import cs from '@/i18n/sangha-admin-cs.json';
import en from '@/i18n/sangha-admin-en.json';
import es from '@/i18n/sangha-admin-es.json';
import it from '@/i18n/sangha-admin-it.json';
import ru from '@/i18n/sangha-admin-ru.json';
import axios from 'axios';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const translations = {
  en: en,
  es: es,
  it: it,
  ru: ru,
  cs: cs,
};

const navigatorLocale =
  (Array.isArray(navigator.languages)
    ? navigator.languages[0]
    : navigator.languages) || 'en-US';

let selectedLocale = navigatorLocale || 'en-US';
if (
  localStorage.sanghaLanguage &&
  translations[localStorage.sanghaLanguage] !== undefined
) {
  selectedLocale = localStorage.sanghaLanguage;
  axios.defaults.headers.common['Accept-Language'] = selectedLocale;
}

export const locale = selectedLocale;

if (selectedLocale.indexOf('-') > -1) {
  selectedLocale = selectedLocale.split('-')[0];
}

// console.log('Setting locale to ', selectedLocale)
export const i18n = new VueI18n({
  locale: selectedLocale,
  fallbackLocale: 'en',
  messages: translations,
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    ru: function (choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }
      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    },
    cs: function (choice) {
      if (choice === 0) {
        return 0;
      }
      if (choice === 1) {
        return 1;
      }
      if (choice > 1 && choice < 5) {
        return 2;
      }
      return 3;
    },
  },
});
