const TOKEN_KEY = 'token';
const JWT_TOKEN_KEY = 'jwttoken';
const JWT_REFRESH_KEY = 'jwtrefresh';
const EMAIL_KEY = 'email';

export const getToken = () => {
  return window.localStorage.getItem(TOKEN_KEY);
};

export const getJWT = () => {
  return window.localStorage.getItem(JWT_TOKEN_KEY);
};

export const getJWTRefresh = () => {
  return window.localStorage.getItem(JWT_REFRESH_KEY);
};

export const getEmail = () => {
  return window.localStorage.getItem(EMAIL_KEY);
};

export const saveToken = ({ token, email }) => {
  window.localStorage.setItem(TOKEN_KEY, token);
  window.localStorage.setItem(EMAIL_KEY, email);
};

export const saveJWT = ({ token, refresh }) => {
  window.localStorage.setItem(JWT_TOKEN_KEY, token);
  window.localStorage.setItem(JWT_REFRESH_KEY, refresh);
};

export const destroyToken = () => {
  window.localStorage.removeItem(TOKEN_KEY);
  window.localStorage.removeItem(EMAIL_KEY);
  window.localStorage.removeItem(JWT_TOKEN_KEY);
  window.localStorage.removeItem(JWT_REFRESH_KEY);
};

export default {
  getToken,
  getJWT,
  getJWTRefresh,
  getEmail,
  saveToken,
  saveJWT,
  destroyToken,
};
