import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/roboto-font/roboto-font.css';
import { Quasar, Notify, Dialog, Loading, LocalStorage } from 'quasar';
import 'quasar/dist/quasar.ie.polyfills';
import Vue from 'vue';
import './styles/quasar.scss';

Vue.use(Quasar, {
  config: {},
  components: {
    /* not needed if importStrategy is not 'manual' */
  },
  directives: {
    /* not needed if importStrategy is not 'manual' */
  },
  plugins: {
    Notify,
    Dialog,
    Loading,
    LocalStorage,
  },
});
