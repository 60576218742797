import * as moment from 'moment-timezone';

const momentPrototype = Object.getPrototypeOf(moment());

momentPrototype.setTime = function (time) {
  if (!time) return this;
  const [hour, minute] = time.split(':');
  return this.set({ hour, minute });
};

export const TimeHelper = {
  /**
   * returns nicely formatted time string from dateTo
   * in form of 08:15
   */
  getTimeString(dateObj, timeStr, timezone) {
    let format = null;
    let tz;

    if (dateObj.indexOf('/') !== -1 && timeStr) {
      format = 'YYYY/MM/DD hh:mm';
      tz = moment(dateObj + ' ' + timeStr, format).tz(timezone);
    } else {
      // console.log("DO", dateObj, "TZ", timezone)
      tz = moment(dateObj).tz(timezone);
    }

    let hours = tz.hours() || 0;
    let minutes = tz.minutes() || 0;
    let ret =
      hours.toString().padStart(2, '0') +
      ':' +
      minutes.toString().padStart(2, '0');
    return ret;
  },

  /**
   * returns timezone in which current device sits
   * if not acccessible, returns UTC
   */
  getDefaultTimezone() {
    return Intl &&
      Intl.DateTimeFormat() &&
      Intl.DateTimeFormat().resolvedOptions()
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : 'UTC';
  },

  timeMatcher: new RegExp(/(?:[01]\d|2[0123]):(?:[012345]\d)/),
};

export const isEquivalent = (a, b) => {
  // Create arrays of property names
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);
  if (aProps.length != bProps.length) {
    return false;
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];
    if (a[propName] !== b[propName]) {
      return false;
    }
  }
  return true;
};

export function areObjectsEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
}

/*
 * Detects navigator locale 24h time preference
 * It works by checking whether hour output contains AM ('1 AM' or '01 h')
 * based on the user's preferred language
 */
export const isBrowserLocale24h = () => {
  return !new Intl.DateTimeFormat(navigator.language, { hour: 'numeric' })
    .format(0)
    .match(/AM/);
};

export function getDayNames(locale = 'en', format = 'long') {
  const formatter = new Intl.DateTimeFormat(locale, {
    weekday: format,
    timeZone: 'UTC',
  });
  const days = [1, 2, 3, 4, 5, 6, 7].map((day) => {
    const dd = day < 10 ? `0${day}` : day;
    return new Date(`2017-01-${dd}T00:00:00+00:00`);
  });
  return days.map((date) => formatter.format(date));
}

export function getMonthNames(locale = 'en', format = 'long') {
  const formatter = new Intl.DateTimeFormat(locale, {
    month: format,
    timeZone: 'UTC',
  });
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
    const mm = month < 10 ? `0${month}` : month;
    return new Date(`2017-${mm}-01T00:00:00+00:00`);
  });
  return months.map((date) => formatter.format(date));
}

export function getFormatDate(value, format) {
  if (!format) format = 'dddd, MMMM Do YYYY, HH:mm z';
  if (!value) return;
  return moment(String(value))
    .tz(TimeHelper.getDefaultTimezone())
    .format(format);
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
