import { router } from '@/main.js';
import TokenService from '@/services/token.service';
import store from '@/stores/store';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { SubscriptionClient } from 'subscriptions-transport-ws';

/*eslint-disable */
const GRAPHQL_ENDPOINT = ENV.SOCKET_URL || 'wss://api.mahasangha.net/graphql/';
/*eslint-enable */

export const subscriptionClient = new SubscriptionClient(GRAPHQL_ENDPOINT, {
  reconnect: true,
  lazy: true,
  connectionParams: () => {
    return {
      Authorization: `JWT ${TokenService.getJWT()}`,
    };
  },
  connectionCallback: (err) => {
    if (err && err.message === 'JSONWebTokenExpired: Signature has expired') {
      store.dispatch('renewAuth').then(
        () => {
          console.log('Token renewed successfully');
        },
        (err) => {
          console.log('Renew of token failed, going to login', err);
          router.replace({
            name: 'login',
            query: { returnTo: router.history.current.fullPath },
          });
        }
      );
    }
  },
});

export const apolloClient = new ApolloClient({
  link: subscriptionClient,
  cache: new InMemoryCache(),
});
