import TokenService from '@/services/token.service';
import store from '@/stores/store';
import axios from 'axios';
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
} from 'axios-extensions';
import Vue from 'vue';
import VueAxios from 'vue-axios';

const RESPONSES = {
  LIST_EVENT_TYPE:
    '{ id, type, image(imageWidth:0,imageHeight:0), attendanceType { id, type }, date, dateTo, description, title, official, cancelled, venue { id, title, description }, tags {id, code, name, category, tagged }, url, password, publicShareLink, createdBy { id } }',
  FULL_EVENT_TYPE:
    '{ id, type, title, description, date, dateTo, official, attendanceType { id, type }, image(imageWidth:640,imageHeight:340), followed, venue { id, title, street, town, country, zipCode, contact, shortLocation, description, timezone, specialVenue, location, image }, tags {id, code, name, category, tagged }, url, password, publicShareLink, recurrentV2Parent { id } }',
  RECURRRENT_EVENT_TYPE:
    '{ date, recurrentV2Parent { date, id, lastPossibleDate, recurrentType, recurrentEvery, recurrentDailyWeekends, recurrentMonthlyBy, recurrentWeeklyDays, recurrentMonthlyByDate, recurrentMonthlyByEach, fePreset } }',
  DUPLICATE_EVENT_TYPE:
    '{ id, type, title, description, date, dateTo, official, attendanceType { id, type }, venue { id, title, description }, url, password }',
  CREATE_EVENT_RESPONSE_TYPE:
    '{ success, event { id, title, date, dateTo }, error }',
  SUCCESS_ERROR_TYPE: '{ success, error }',
  SUCCESS_ERRORS_TYPE: '{ success, errors }',
  LIST_TOPIC_TYPE:
    '{ title, official, createdAt, lastActivityAt, joinedCount, channelId, read, image(imageWidth:60,imageHeight:60), isPrivate }',
  FULL_TOPIC_TYPE:
    '{ title, description, official, createdAt, lastActivityAt, createdBy { id, fullName, avatar(imageWidth:100,imageHeight:100) }, joinedCount, channelId, read, channelJoined { moderator }, image(imageWidth:160,imageHeight:160), isPrivate, tags {id, code, name, category, tagged } }',
  ONLY_MEMBERS_TOPIC_TYPE:
    '{ channelId, joinedUsers(limit:$limit, offset:$offset, withoutMe:$withoutMe) { id, fullName, avatar(imageWidth:60,imageHeight:60), connection } }',
  LIST_POSTS_TYPE:
    '{ id, createdAt, deletedAt, editedAt, author { id, fullName, avatar(imageWidth:80,imageHeight:80) }, blocks { index, data:data(imageWidth: $imageWidth), original:data, type }, wasSeen, read, channelId, reactions {reaction, count, reacted } }',
  FULL_CHAT_TYPE:
    '{ user { id, fullName, avatar(imageWidth:80,imageHeight:80), isBlocked, blockState, user { lastActivity } }, channelId, messages { id, author { id, avatar(imageWidth:80,imageHeight:80) }, deletedAt, editedAt, blocks { index, data:data(imageWidth: $imageWidth), original:data, type }, createdAt, read, reactions {reaction, count, reacted } } }',
  LIST_CHAT_TYPE:
    '{ userProfile { id, fullName, avatar(imageWidth:80,imageHeight:80), isBlocked, blockState }, channelId, lastMessage { author { id }, read, blocks { index, data, type }, createdAt } }',
  LIST_USERS_TYPE:
    '{ id, channelId, fullName, avatar(imageWidth:80,imageHeight:80), user { lastActivity }, since }',
  UPLOAD_FILE_RESPONSE_TYPE: '{ success, fileUrl, errors }',
  UNREAD_CHANNELS_TYPE:
    '{ unreadChannelsCount, unreadDirectMessagesCount, unreadNotificationCount }',
  CREATE_TOPIC_RESPONSE_TYPE: '{ success, topic { channelId, title }, error }',
};

const DEBUG = false;

/*eslint-disable */
const API_URL = ENV.API_URL || '/';
const GQL_URL = ENV.GRAPHQL_URL || API_URL;
/*eslint-enable */

let http = null;

export const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    http = Vue.axios.create({
      baseURL: API_URL,
      adapter: throttleAdapterEnhancer(
        cacheAdapterEnhancer(axios.defaults.adapter, {
          enabledByDefault: false,
          cacheFlag: 'cached',
        })
      ),
    });
  },

  setHeader() {
    http.defaults.headers.common[
      'Authorization'
    ] = `X_ONBOARDING_TOKEN ${TokenService.getToken()}`;
  },

  setJWTHeader() {
    http.defaults.headers.common[
      'Authorization'
    ] = `JWT ${TokenService.getJWT()}`;
  },

  enableInterceptor() {
    http.interceptors.response.use(
      (response) => {
        DEBUG && console.log('Interceptor fired');
        if (!response)
          return new Promise((resolve, reject) => {
            reject(response);
          });

        if (
          response.data &&
          response.data.errors &&
          response.data.errors[0] &&
          (!response.data.errors[0].context ||
            response.data.errors[0].context.status !== 401)
        ) {
          return new Promise((resolve, reject) => {
            reject(response);
          });
        }

        if (
          response.data &&
          response.data.errors &&
          response.data.errors[0] &&
          response.data.errors[0].context &&
          response.data.errors[0].context.status === 401
        ) {
          DEBUG && console.log('Interceptor: got 401');

          if (store.state.auth.renewing) {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                const config = response.config;
                config.headers[
                  'Authorization'
                ] = `JWT ${TokenService.getJWT()}`;

                axios
                  .request(config)
                  .then((response) => {
                    resolve(response);
                  })
                  .catch((er) => {
                    reject(er);
                  });
              }, 4000);
            });
          }
          delete http.defaults.headers.common['Authorization'];

          return store.dispatch('renewAuth').then(
            (ok) => {
              response.config.headers['Authorization'] = `JWT ${ok.token}`;
              http.defaults.headers.common['Authorization'] = `JWT ${ok.token}`;
              return new Promise((resolve, reject) => {
                DEBUG && console.log('Interceptor: refetch original');

                axios
                  .request(response.config)
                  .then((response2) => {
                    resolve(response2);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              });
            },
            (err) => {
              console.error(err);
            }
          );
        }

        return new Promise((resolve) => {
          DEBUG && console.log('Interceptor: pass');
          resolve(response);
        });
      },
      (error) => {
        // Return any error which is not due to authentication back to the calling service
        DEBUG && console.log('Interceptor: error', error);
      }
    );
  },

  clearHeader() {
    delete http.defaults.headers.common['Authorization'];
  },

  query(resource, params) {
    return http.get(resource, params).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },

  get(resource) {
    return http.get(`${resource}`).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },

  post(resource, params) {
    return http.post(`${resource}`, params);
  },

  patch(resource, params) {
    return http.patch(`${resource}`, params);
  },

  update(resource, slug, params) {
    return http.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return http.put(`${resource}`, params);
  },

  delete(resource) {
    return http.delete(resource).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },
};

export const Events = {
  PAGE_LIMIT: 15,

  listCreated(options) {
    let params = {
      created_by_user_id: options.profile_id,
      limit: options.limit,
      offset: (options.page > 0 ? options.page - 1 : 0) * options.limit,
      upcoming: true,
      ongoing: true,
      going: options.going ? true : undefined,
      languages: options.languages,
      teachings: options.teachings,
    };
    if (options.from_date) {
      params.upcoming = false;
      // params.ongoing = false;
      params.date_from = options.from_date;
    }
    if (options.query != '') params.search_string = options.query;
    if (options.to_date) params.date_to = options.to_date;
    if (options.only_venue) params.venue_id = options.only_venue;
    if (options.only_official) params.only_official = true;
    if (options.privacy) params.attendance_type = options.privacy;
    if (options.sortBy) {
      let sortOptions = [
        { from: 'dateFrom', to: 'date' },
        { from: 'title', to: 'title' },
        { from: 'venue', to: 'venue__title' },
        { from: 'attendanceType', to: 'attendance_type__type' },
      ];
      let order = sortOptions.filter((item) => item.from == options.sortBy)[0]
        .to;
      params.order_by =
        (options.order && options.order == 'desc' ? '-' : '') + order;
    }
    
    if (
      !options.showCancelled || 
      options.showCancelled === 'all' ||
      options.showCancelled === 'only-cancelled'
    )
      params.show_cancelled = true;
    if (options.showCancelled === 'only-cancelled')
      params.show_only_cancelled = true;
  

    return new Promise((resolve, reject) => {
      ApiService.post(GQL_URL, {
        query: `
          query($limit:Int, $offset:Int, $searchString:String, $createdByUserId:UUID, $upcoming:Boolean, $ongoing:Boolean, $onlyOfficial:Boolean, $showOnlyCancelled:Boolean, $showCancelled:Boolean, $attendanceTypeId:Int, $venueId:UUID, $orderBy:String, $dateFrom:DateTime, $dateTo:DateTime, $going:Boolean, $languages:[String], $teachings:[String]) { 
            getEventsSanghaWeb(limit:$limit, offset:$offset, searchString:$searchString, createdByUserId:$createdByUserId, upcoming:$upcoming, ongoing:$ongoing, onlyOfficial:$onlyOfficial, showOnlyCancelled:$showOnlyCancelled, showCancelled:$showCancelled, attendanceTypeId:$attendanceTypeId, venueId:$venueId, orderBy:$orderBy, dateFrom:$dateFrom, dateTo:$dateTo, going:$going, languages:$languages, teachings:$teachings) 
              ${RESPONSES.LIST_EVENT_TYPE}
          }
        `,
        variables: {
          limit: params.limit + 1,
          offset: params.offset,
          dateFrom: params.date_from,
          dateTo: params.date_to,
          orderBy: params.order_by,
          searchString: params.search_string,
          createdByUserId: params.created_by_user_id,
          upcoming: params.upcoming,
          ongoing: params.ongoing,
          onlyOfficial: params.only_official,
          showOnlyCancelled: params.show_only_cancelled,
          showCancelled: params.show_cancelled,
          attendanceTypeId: params.attendance_type,
          venueId: params.venue_id,
          going: params.going,
          languages: params.languages,
          teachings: params.teachings,
        },
      }).then(
        (res) => {
          if (res.data && res.data.data && res.data.data.getEventsSanghaWeb) {
            const events = res.data.data.getEventsSanghaWeb;
            resolve({
              hasNextPage: events.length > params.limit,
              hasPrevPage: params.offset > 0,
              data: {
                data: {
                  getEvents: events.slice(0, params.limit),
                },
              },
            });
          } else {
            reject(res);
          }
        },
        (err) => reject(err)
      );
    });
  },

  listGoing(options) {
    let params = {
      limit: options.limit,
      offset: (options.page > 0 ? options.page - 1 : 0) * options.limit,
      languages: options.languages,
      teachings: options.teachings,
      query: options.query,
      filterDate: options.filterDate,
      filterLocation: options.filterLocation,
      date: options.filterDateSelected,
    };
    return new Promise((resolve, reject) => {
      ApiService.post(GQL_URL, {
        query: `
          query($limit:Int, $offset:Int, $languages:[String], $teachings:[String], $searchString:String, $filterDate:EVENT_FILTER_DATE_ENUM, $filterLocation:EVENT_FILTER_LOCATION_ENUM, $date:String) { 
            eventsFollowed(limit:$limit, offset:$offset, languages:$languages, teachings:$teachings, searchString:$searchString, filterDate:$filterDate, filterLocation:$filterLocation, date:$date)
              ${RESPONSES.LIST_EVENT_TYPE}
          }
        `,
        variables: {
          limit: params.limit + 1,
          offset: params.offset,
          languages: params.languages,
          teachings: params.teachings,
          searchString: params.query,
          filterDate: params.filterDate,
          filterLocation: params.filterLocation,
          date: params.date,
        },
      }).then(
        (res) => {
          if (res.data && res.data.data && res.data.data.eventsFollowed) {
            const events = res.data.data.eventsFollowed;
            resolve({
              hasNextPage: events.length > params.limit,
              hasPrevPage: params.offset > 0,
              data: {
                data: {
                  getEvents: events.slice(0, params.limit),
                },
              },
            });
          } else {
            reject(res);
          }
        },
        (err) => reject(err)
      );
    });
  },

  listSuggested(options) {
    let params = {
      limit: options.limit,
      offset: (options.page > 0 ? options.page - 1 : 0) * options.limit,
      languages: options.languages,
      teachings: options.teachings,
      query: options.query,
      filterDate: options.filterDate,
      filterLocation: options.filterLocation,
      date: options.filterDateSelected,
    };
    return new Promise((resolve, reject) => {
      ApiService.post(GQL_URL, {
        query: `
          query($limit:Int, $offset:Int, $languages:[String], $teachings:[String], $searchString:String, $filterDate:EVENT_FILTER_DATE_ENUM, $filterLocation:EVENT_FILTER_LOCATION_ENUM, $date:String) { 
            eventsGetSuggestions(limit:$limit, offset:$offset, languages:$languages, teachings:$teachings, searchString:$searchString, filterDate:$filterDate, filterLocation:$filterLocation, date:$date)
              ${RESPONSES.LIST_EVENT_TYPE}
          }
        `,
        variables: {
          limit: params.limit + 1,
          offset: params.offset,
          languages: params.languages,
          teachings: params.teachings,
          searchString: params.query,
          filterDate: params.filterDate,
          filterLocation: params.filterLocation,
          date: params.date,
        },
      }).then(
        (res) => {
          if (res.data && res.data.data && res.data.data.eventsGetSuggestions) {
            const events = res.data.data.eventsGetSuggestions;
            resolve({
              hasNextPage: events.length > params.limit,
              hasPrevPage: params.offset > 0,
              data: {
                data: {
                  getEvents: events.slice(0, params.limit),
                },
              },
            });
          } else {
            reject(res);
          }
        },
        (err) => reject(err)
      );
    });
  },

  listDuplicateEvents(data) {
    return ApiService.post(GQL_URL, {
      query: `
        query($venueId:UUID!,$exactDateFrom:DateTime!,$isOnline:Boolean) { 
          duplicateEvents(venueId:$venueId, exactDateFrom:$exactDateFrom, isOnline:$isOnline) 
            ${RESPONSES.DUPLICATE_EVENT_TYPE}
        }
      `,
      variables: {
        venueId: data.venue_id,
        exactDateFrom: data.date,
        isOnline: data.checkHybrid,
      },
    });
  },

  getSingle(id) {
    return ApiService.post(GQL_URL, {
      query: `
        query($eventId:UUID!) { 
          getEvent(eventId:$eventId) 
            ${RESPONSES.FULL_EVENT_TYPE}
        }
      `,
      variables: {
        eventId: id,
      },
    });
  },

  add(data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      const obj = {
        excludeFollow: data.exclude_automatic_follow,
        event: {
          title: data.title,
          description: data.description,
          date: data.date,
          dateTo: data.date_to,
          venueId: data.venue_id,
          attendanceTypeId: data.attendance_type_id,
          official: data.official,
          tags: data.tags,
          url: data.url,
          password: data.password,
          image: null,
          timezoneEdit: data.timezone,
          type: data.type,
        },
      };

      const operations = {
        query: `
        mutation ($event:EventInputType!, $excludeFollow:Boolean) { 
          createEvent(event:$event, excludeAutomaticFollow: $excludeFollow) 
            ${RESPONSES.CREATE_EVENT_RESPONSE_TYPE} 
        }`,
        variables: obj,
      };

      if (data.image) {
        const map = {
          image: ['variables.event.image'],
        };
        formData.append('map', JSON.stringify(map));
        formData.append('image', data.image);
      }
      formData.append('operations', JSON.stringify(operations));

      ApiService.post(GQL_URL, formData).then(
        (res) => {
          if (!res || !res.data || !res.data.data || !res.data.data.createEvent)
            reject('API Error');
          if (res.data.data.createEvent && res.data.data.createEvent.success)
            resolve(true);
          if (res.data.data.createEvent.error)
            reject(res.data.data.createEvent.error);
          reject('Unknown API error');
        },
        () => {
          reject('Response Error');
        }
      );
    });
  },

  addRecurrent(data) {
    return new Promise((resolve, reject) => {
      // recurrent event
      const formData = new FormData();

      const obj = {
        event: {
          title: data.title,
          description: data.description,
          date: data.date,
          dateTo: data.date_to,
          venueId: data.venue_id,
          attendanceTypeId: data.attendance_type_id,
          official: data.official,
          tags: data.tags,
          url: data.url,
          password: data.password,
          image: null,
          timezoneEdit: data.timezone,
          type: data.type,
          lastPossibleDate: data.last_possible_date,
          recurrentType: data.recurrent_type,
          recurrentEvery: data.recurrent_every,
          recurrentDailyWeekends: data.recurrent_daily_weekends,
          recurrentMonthlyBy: data.recurrent_monthly_by,
          recurrentWeeklyDays: data.recurrent_weekly_days,
          recurrentMonthlyByDate: data.recurrent_monthly_by_date,
          recurrentMonthlyByEach: data.recurrent_monthly_by_each,
          fePreset: data.recurrent_preset,
        },
        excludeAutomaticFollow: data.exclude_automatic_follow,
      };

      const operations = {
        query: `
        mutation ($event:RecurrentEventInputType!, $excludeAutomaticFollow:Boolean) { 
          createRecurrentEventV2(event:$event,excludeAutomaticFollow:$excludeAutomaticFollow) 
            { success, event { id, title, date, dateTo }, error } 
        }`,
        variables: obj,
      };

      if (data.image) {
        const map = {
          image: ['variables.event.image'],
        };
        formData.append('map', JSON.stringify(map));
        formData.append('image', data.image);
      }
      formData.append('operations', JSON.stringify(operations));

      ApiService.post(GQL_URL, formData).then(
        (res) => {
          if (
            !res ||
            !res.data ||
            !res.data.data ||
            !res.data.data.createRecurrentEventV2
          )
            reject('API Error');
          if (
            res.data.data.createRecurrentEventV2 &&
            res.data.data.createRecurrentEventV2.success
          )
            resolve(true);
          if (res.data.data.createRecurrentEventV2.error)
            reject(res.data.data.createRecurrentEventV2.error);
          reject('Unknown API error');
        },
        () => {
          reject('Response Error');
        }
      );
    });
  },

  updateRecurrent(id, parentId, data, forceEdit) {
    return new Promise((resolve, reject) => {
      // recurrent event
      const formData = new FormData();

      const obj = {
        firstEventId: id,
        forceEdit: !!forceEdit,
        recurrentEventId: parentId,
        event: {
          title: data.title,
          description: data.description,
          date: data.date,
          dateTo: data.date_to,
          venueId: data.venue_id,
          attendanceTypeId: data.attendance_type_id,
          official: data.official,
          tags: data.tags,
          url: data.url,
          password: data.password,
          image: null,
          timezoneEdit: data.timezone,
          type: data.type,
          lastPossibleDate: data.last_possible_date,
          recurrentType: data.recurrent_type,
          recurrentEvery: data.recurrent_every,
          recurrentDailyWeekends: data.recurrent_daily_weekends,
          recurrentMonthlyBy: data.recurrent_monthly_by,
          recurrentWeeklyDays: data.recurrent_weekly_days,
          recurrentMonthlyByDate: data.recurrent_monthly_by_date,
          recurrentMonthlyByEach: data.recurrent_monthly_by_each,
          fePreset: data.recurrent_preset,
        },
        excludeAutomaticFollow: data.exclude_automatic_follow,
      };

      const operations = {
        query: `
        mutation ($firstEventId:UUID!, $forceEdit:Boolean!, $recurrentEventId:UUID!, $event:RecurrentEventInputType!) { 
          editRecurrentEventV2(firstEventId:$firstEventId, forceEdit:$forceEdit, recurrentEventId:$recurrentEventId, event:$event) 
            { success, event { id, title, date, dateTo }, error } 
        }`,
        variables: obj,
      };

      if (data.image) {
        const map = {
          image: ['variables.event.image'],
        };
        formData.append('map', JSON.stringify(map));
        formData.append('image', data.image);
      }
      formData.append('operations', JSON.stringify(operations));

      ApiService.post(GQL_URL, formData).then(
        (res) => {
          if (
            !res ||
            !res.data ||
            !res.data.data ||
            !res.data.data.editRecurrentEventV2
          )
            reject('API Error');
          if (
            res.data.data.editRecurrentEventV2 &&
            res.data.data.editRecurrentEventV2.success
          )
            resolve(res.data.data.editRecurrentEventV2);
          if (res.data.data.editRecurrentEventV2.error)
            reject(res.data.data.editRecurrentEventV2.error);
          reject('Unknown API error');
        },
        () => {
          reject('Response Error');
        }
      );
    });
  },

  testRecurrent(data) {
    return ApiService.post(GQL_URL, {
      query: `query ($firstDate: DateTime!, $firstDateTo: DateTime!, $lastPossibleDate: DateTime!, $recurrentType: RecurrentTypeEnum!, $recurrentEvery: Int!, $recurrentDailyWeekends: Boolean, $recurrentMonthlyBy: RecurrentMonthlyByEnum, $recurrentWeeklyDays: [RecurrentWeekDaysEnum!], $recurrentMonthlyByDate: [Int!], $recurrentMonthlyByEach: [Int!]) { 
        checkRecurrentDates (firstDate:$firstDate, firstDateTo:$firstDateTo, lastPossibleDate:$lastPossibleDate, recurrentType:$recurrentType, recurrentEvery:$recurrentEvery, recurrentDailyWeekends:$recurrentDailyWeekends, recurrentMonthlyBy:$recurrentMonthlyBy, recurrentWeeklyDays:$recurrentWeeklyDays, recurrentMonthlyByDate:$recurrentMonthlyByDate, recurrentMonthlyByEach:$recurrentMonthlyByEach) 
        { dates, count } 
      }`,
      variables: {
        ...data,
      },
    });
  },

  loadRecurrentDetails(eventId) {
    return ApiService.post(GQL_URL, {
      query: `query($eventId:UUID!) { 
        getEvent(eventId:$eventId) 
          ${RESPONSES.RECURRRENT_EVENT_TYPE}
      }`,
      variables: {
        eventId,
      },
    });
  },

  addImage(id, formData) {
    return ApiService.patch(`v3/events/${id}/`, formData);
  },

  delete(id) {
    return ApiService.post(GQL_URL, {
      query: `
      mutation($eventId:UUID!) { 
        cancelEvent(eventId:$eventId)
          ${RESPONSES.SUCCESS_ERROR_TYPE}
      }`,
      variables: {
        eventId: id,
      },
    });
  },

  follow(id) {
    return ApiService.post(GQL_URL, {
      query: `
      mutation($eventId:UUID!) { 
        followEvent(eventId:$eventId)
          ${RESPONSES.SUCCESS_ERROR_TYPE}
      }`,
      variables: {
        eventId: id,
      },
    });
  },

  unfollow(id) {
    return ApiService.post(GQL_URL, {
      query: `
      mutation($eventId:UUID!) { 
        unfollowEvent(eventId:$eventId)
          ${RESPONSES.SUCCESS_ERROR_TYPE}
      }`,
      variables: {
        eventId: id,
      },
    });
  },

  update(id, data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      const obj = {
        eventId: id,
        event: {
          title: data.title,
          description: data.description,
          date: data.date,
          dateTo: data.date_to,
          venueId: data.venue_id,
          attendanceTypeId: data.attendance_type_id,
          official: data.official,
          tags: data.tags,
          url: data.url,
          password: data.password,
          timezoneEdit: data.timezone,
          type: data.type,
        },
      };

      let operations = {
        query: `
        mutation ($event:EventInputType!, $eventId:UUID!) { 
          editEvent(event:$event,eventId:$eventId) 
            ${RESPONSES.CREATE_EVENT_RESPONSE_TYPE}
        }`,
        variables: obj,
      };

      if (data.image) {
        operations.variables.event.image = null;
        const map = {
          image: ['variables.event.image'],
        };
        formData.append('map', JSON.stringify(map));
        formData.append('image', data.image);
      }

      formData.append('operations', JSON.stringify(operations));

      ApiService.post(GQL_URL, formData).then(
        (res) => {
          if (!res || !res.data || !res.data.data || !res.data.data.editEvent)
            reject('API Error');
          if (res.data.errors || res.data.data.editEvent.error)
            reject(res.data.errors || res.data.data.editEvent.error);
          if (res.data.data.editEvent && res.data.data.editEvent.success)
            resolve(true);
          reject('Unknown API error');
        },
        () => {
          reject('Response Error');
        }
      );
    });
  },

  /** public event */
  fetch(id) {
    return ApiService.post(GQL_URL, {
      query: `
        query($eventId:UUID!) { 
          getPublicEvent(eventId:$eventId)
            ${RESPONSES.FULL_EVENT_TYPE}
        }`,
      variables: {
        eventId: id,
      },
    });
  },

  list(options) {
    let params = options;
    return ApiService.query('v3/events/', { params: params });
  },

  getPage(id) {
    return ApiService.query(`v3/pages/event/${id}/`);
  },
};

export const Auth = {
  startRecovery(data) {
    return ApiService.post(GQL_URL, {
      query: `
      mutation ($email:String!,$fromWebadmin:Boolean) { 
        startOnboarding(email:$email, fromWebadmin:$fromWebadmin) 
          { onboarding { token }, success, error } 
      }`,
      variables: { email: data.email, fromWebadmin: true },
    });
  },

  finishRecovery(data) {
    return ApiService.post(GQL_URL, {
      query: `
      mutation($verificationCode:String!) { 
        verifyOnboarding(verificationCode:$verificationCode) 
          { onboarding { token, language, existingUser { id } }, success, error } 
      }`,
      variables: {
        verificationCode: data.email_verification_code,
      },
    });
  },

  login() {
    return ApiService.post(GQL_URL, {
      query: `
      mutation { 
        sanghaJwt(mantra:"RAM YAM KHAM OM A HUM") 
          { jwtSangha { token, refreshToken, mantra }, success, error } 
      }`,
    });
  },

  renew(data) {
    return ApiService.post(GQL_URL, {
      query: `
      mutation($refreshToken: String!) { 
        refreshToken(mantra:"RAM YAM KHAM OM A HUM", refreshToken:$refreshToken)
          { jwtSangha { token, refreshToken, mantra }, success, error } 
      }`,
      variables: {
        refreshToken: data.refresh,
      },
    });
  },

  checkStatus() {
    return ApiService.get('v3/token/status/');
  },
};

export const Venues = {
  search(query, options) {
    let params = {
      searchString: query,
    };
    if (options && options.limit) params.limit = options.limit;
    if (options && options.offset) params.offset = options.offset;

    return ApiService.post(GQL_URL, {
      query: `
        query($searchString:String,$limit:Int,$offset:Int) { 
          searchVenues(searchString:$searchString,limit:$limit,offset:$offset) 
            { id, title, description, specialVenue, image, street, town, country, contact, timezone } 
        }
      `,
      variables: params,
    });
  },

  searchGeo(lat, lon) {
    return ApiService.query('v3/venues/', {
      params: {
        user_latitude: parseFloat(lat),
        user_longitude: parseFloat(lon),
        sort_by_distance: true,
        limit: 10,
        offset: 0,
      },
      cached: true,
    });
  },

  add(data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      const operations = {
        query: `
        mutation ($venue:VenueInputType!) { 
          createVenue(venue: $venue)
            { success, error, venue { id, title, description, specialVenue, image, mapImage, street, town, country, contact, timezone } } 
        }`,
        variables: {
          venue: {
            title: data.title,
            description: data.description,
            image: null,
            address: data.address,
            latitude: data.latitude,
            longitude: data.longitude,
          },
        },
      };

      if (data.image) {
        const map = {
          image: ['variables.venue.image'],
        };
        formData.append('map', JSON.stringify(map));
        formData.append('image', data.image);
      }

      formData.append('operations', JSON.stringify(operations));

      ApiService.post(GQL_URL, formData).then(
        (res) => {
          if (!res || !res.data || !res.data.data || !res.data.data.createVenue)
            reject('API Error');
          if (res.data.data.createVenue && res.data.data.createVenue.success)
            resolve(res.data.data.createVenue.venue);
          if (res.data.data.createVenue.error != null)
            reject(res.data.data.createVenue.error);
        },
        (err) => reject(err)
      );
    });
  },

  listSpecial() {
    return ApiService.post(GQL_URL, {
      query: `
        query($specialVenues:Boolean!) { 
          searchVenues(specialVenues:$specialVenues) 
            { id, title, description, specialVenue } 
        }
      `,
      variables: {
        specialVenues: true,
      },
    });
  },

  detail(id) {
    return ApiService.post(GQL_URL, {
      query: `
        query($venueId:UUID!) { 
          getVenue(venueId:$venueId) 
            { id, title, description, specialVenue, image, mapImage, street, town, country, contact, timezone, zipCode } 
        }
      `,
      variables: {
        venueId: id,
      },
    });
  },

  searchForUser(user_id) {
    return ApiService.post(GQL_URL, {
      query: `
        query ($limit:Int, $offset:Int, $userId:UUID!) { 
          getVenuesOfUserEvents(limit:$limit, offset:$offset, userId:$userId) { id, title, description } 
        }
      `,
      variables: {
        limit: 100,
        offset: 0,
        userId: user_id,
      },
    });
  },

  getPage(id) {
    return ApiService.query(`v3/pages/venue/${id}/`);
  },
};

export const AttendanceTypes = {
  cache: null,

  list() {
    return new Promise((resolve, reject) => {
      if (this.cache) {
        return resolve({ data: { data: { attendanceTypes: this.cache}}});
      }

      ApiService.post(GQL_URL, {
        query: `
          query { 
            attendanceTypes { id, type } 
          }
        `,
      }).then((res) => {
        this.cache = res.data.data.attendanceTypes;
        resolve(res);

      }).catch(() => reject());
    });
  },
};

export const Tags = {
  cache:{},
  
  list(category) {
    if (!category) category = null;
    return new Promise((resolve, reject) => {
      if (this.cache && category && this.cache[category]) {
        console.log()
        return resolve({ data: { data: { getTags: this.cache[category]}}});
      }
      ApiService.post(GQL_URL, {
        query: `
          query ($category: TAG_CATEGORY_ENUM) { 
            getTags(category: $category) { id, code, name, category, tagged } 
          }
        `,
        variables: {
          category,
        },
      }).then((res) => {
        if (category) {
          this.cache[category] = res.data.data.getTags;
        }
        resolve(res);
      }).catch(() => reject());
    });
  },
};

export const Address = {
  get(params) {
    return ApiService.post(GQL_URL, {
      query: `
        query($searchString:String!, $myLanguage:String) { 
          searchLocation(searchString:$searchString, myLanguage:$myLanguage, exactAddress: true) 
            { type, name, street, houseNo, district, city, country, zipCode, latitude, longitude } 
        }
      `,
      variables: {
        searchString: params.searchString,
        myLanguage: params.language,
      },
    });
  },
};

export const Profile = {
  my() {
    return ApiService.post(GQL_URL, {
      query: `
      query { 
        getSelf {
          id, fullName, avatar
        } 
      }`,
    });
  },

  alendar() {
    return ApiService.post(GQL_URL, {
      query: `
      query { 
        getSelf {
          fullName, birthday, language
        } 
      }`,
    });
  },

  getUnreadTopics() {
    return ApiService.post(GQL_URL, {
      query: `
      query { 
        getUnreadChannelsCount 
          ${RESPONSES.UNREAD_CHANNELS_TYPE}
        
      }`,
    });
  },
};

export const Files = {
  upload(file) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      if (!file) reject('No file provided');

      const obj = {
        file: null,
      };

      const operations = {
        query: `
        mutation ($file:Upload!) { 
          uploadFile(file:$file) 
            ${RESPONSES.UPLOAD_FILE_RESPONSE_TYPE} 
        }`,
        variables: obj,
      };

      const map = {
        file: ['variables.file'],
      };
      formData.append('map', JSON.stringify(map));
      formData.append('file', file);
      formData.append('operations', JSON.stringify(operations));

      ApiService.post(GQL_URL, formData).then(
        (res) => {
          if (!res || !res.data || !res.data.data || !res.data.data.uploadFile)
            reject('API Error');
          if (res.data.data.uploadFile && res.data.data.uploadFile.success)
            resolve(res.data.data.uploadFile);
          if (res.data.data.uploadFile.error)
            reject(res.data.data.uploadFile.error);
          reject('Unknown API error');
        },
        () => {
          reject('Response Error');
        }
      );
    });
  },
};

export const Topics = {
  list(options) {
    let params = {};
    if (options.offset) params.offset = options.offset;
    if (options.limit) params.limit = options.limit;
    if (options.search_string) params.searchString = options.search_string;

    return new Promise((resolve, reject) => {
      ApiService.post(GQL_URL, {
        query: `
          query($searchString: String, $limit: Int, $offset: Int) { 
            getTopics(searchString:$searchString, limit:$limit, offset:$offset) 
              ${RESPONSES.LIST_TOPIC_TYPE}
          }
        `,
        variables: params,
      }).then(
        (res) => {
          if (res.data && res.data.data && res.data.data.getTopics) {
            const topics = res.data.data.getTopics;
            resolve({
              hasNextPage: topics.length > this.PAGE_LIMIT,
              hasPrevPage: params.offset > 0,
              data: {
                data: {
                  getTopics: topics.slice(0, this.PAGE_LIMIT),
                },
              },
            });
          } else {
            reject(res);
          }
        },
        (err) => reject(err)
      );
    });
  },

  listJoined(options) {
    this.listTopics({ ...options, type: 'following' });
  },

  listTopics(options) {
    let params = {};
    if (options.offset) params.offset = options.offset;
    if (options.limit) params.limit = options.limit + 1;
    if (options.search_string) params.searchString = options.search_string;

    let target;
    if (options.type == 'following') target = 'getJoinedTopics';
    if (options.type == 'created') target = 'getCreatedTopics';
    if (options.type == 'suggested') target = 'getSuggestedTopics';

    return new Promise((resolve, reject) => {
      ApiService.post(GQL_URL, {
        query: `
          query($searchString: String, $limit: Int, $offset: Int) { 
            ${target}(searchString:$searchString, limit:$limit, offset:$offset) 
              ${RESPONSES.LIST_TOPIC_TYPE}
          }
        `,
        variables: params,
      }).then(
        (res) => {
          if (res.data && res.data.data && res.data.data[target]) {
            const topics = res.data.data[target];
            resolve({
              hasNextPage: topics.length > options.limit,
              hasPrevPage: params.offset > 0,
              data: {
                data: {
                  getTopics: topics.slice(0, options.limit),
                },
              },
            });
          } else {
            reject(res);
          }
        },
        (err) => reject(err)
      );
    });
  },

  get(id) {
    // let params = options;

    return ApiService.post(GQL_URL, {
      query: `
        query($channelId: UUID!) { 
          getTopic(channelId:$channelId) 
            ${RESPONSES.FULL_TOPIC_TYPE}
        }
      `,
      variables: {
        channelId: id,
      },
    });
  },

  follow(id) {
    return ApiService.post(GQL_URL, {
      query: `
      mutation($channelId:UUID!) { 
        followTopic(channelId:$channelId)
          ${RESPONSES.SUCCESS_ERROR_TYPE}
      }`,
      variables: {
        channelId: id,
      },
    });
  },

  unfollow(id) {
    return ApiService.post(GQL_URL, {
      query: `
      mutation($channelId:UUID!) { 
        unfollowTopic(channelId:$channelId)
          ${RESPONSES.SUCCESS_ERROR_TYPE}
      }`,
      variables: {
        channelId: id,
      },
    });
  },

  mute(id) {
    return ApiService.post(GQL_URL, {
      query: `
      mutation($channelId:UUID!) { 
        muteTopic(channelId:$channelId)
          ${RESPONSES.SUCCESS_ERROR_TYPE}
      }`,
      variables: {
        channelId: id,
      },
    });
  },

  unmute(id) {
    return ApiService.post(GQL_URL, {
      query: `
      mutation($channelId:UUID!) { 
        unmuteTopic(channelId:$channelId)
          ${RESPONSES.SUCCESS_ERROR_TYPE}
      }`,
      variables: {
        channelId: id,
      },
    });
  },

  listChannelMembers(options) {
    let params = { withoutMe: false };
    if (options.offset) params.offset = options.offset;
    if (options.channelId) params.channelId = options.channelId;
    if (options.limit) params.limit = options.limit + 1;
    if (options.excludeSelf) params.withoutMe = true;

    const target = 'getTopic';

    return new Promise((resolve, reject) => {
      ApiService.post(GQL_URL, {
        query: `
          query($channelId: UUID!, $limit:Int, $offset:Int) { 
            ${target}(channelId:$channelId) 
              ${RESPONSES.ONLY_MEMBERS_TOPIC_TYPE.replace(
                '$withoutMe',
                params.withoutMe
              )}
          }
        `,
        variables: params,
      }).then(
        (res) => {
          if (res.data && res.data.data && res.data.data[target]) {
            const topics = res.data.data[target].joinedUsers;
            resolve({
              hasNextPage: topics.length > options.limit,
              hasPrevPage: params.offset > 0,
              data: {
                data: {
                  getTopic: topics.slice(0, options.limit),
                },
              },
            });
          } else {
            reject(res);
          }
        },
        (err) => reject(err)
      );
    });
  },

  listConversations(options) {
    let params = {};
    if (options.offset) params.offset = options.offset;
    if (options.limit) params.limit = options.limit + 1;

    const target = 'usersGetLastChats';

    return new Promise((resolve, reject) => {
      ApiService.post(GQL_URL, {
        query: `
          query($limit: Int, $offset: Int) { 
            ${target}(limit:$limit, offset:$offset) 
              ${RESPONSES.LIST_CHAT_TYPE}
          }
        `,
        variables: params,
      }).then(
        (res) => {
          if (res.data && res.data.data && res.data.data[target]) {
            const topics = res.data.data[target];
            resolve({
              hasNextPage: topics.length > options.limit,
              hasPrevPage: params.offset > 0,
              data: {
                data: {
                  getChats: topics.slice(0, options.limit),
                },
              },
            });
          } else {
            reject(res);
          }
        },
        (err) => reject(err)
      );
    });
  },

  getChat(id) {
    return ApiService.post(GQL_URL, {
      query: `
        query($channelId: UUID!) { 
          getChat(channelId:$channelId) 
            ${RESPONSES.FULL_CHAT_TYPE.replace('$imageWidth', 400)}
        }
      `,
      variables: {
        channelId: id,
      },
    });
  },

  create(data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      const obj = {
        image: data.image,
        invitedUsers: data.invitedUsers || [],
        topic: {
          title: data.name,
          description: data.description,
          image: null,
          isOfficial: false, //data.official || false,
          isPrivate: data.channel_type == 'private',
          tags: [
            ...data.languages.map((i) => (i && i.code ? i.code : null)),
            ...data.tags.map((i) => (i && i.code ? i.code : null)),
          ],
        },
      };

      const operations = {
        query: `
        mutation ($topic:TopicInputType!, $invitedUsers:[UUID!]) { 
          createTopic(topic: $topic, invitedUsers: $invitedUsers) 
            ${RESPONSES.CREATE_TOPIC_RESPONSE_TYPE} 
        }`,
        variables: obj,
      };

      if (data.image) {
        const map = {
          image: ['variables.topic.image'],
        };
        formData.append('map', JSON.stringify(map));
        formData.append('image', data.image);
      }
      formData.append('operations', JSON.stringify(operations));

      ApiService.post(GQL_URL, formData).then(
        (res) => {
          if (!res || !res.data || !res.data.data || !res.data.data.createTopic)
            return reject('API Error');
          if (res.data.data.createTopic && res.data.data.createTopic.success)
            return resolve(res.data.data.createTopic);
          if (res.data.data.createTopic.error)
            return reject(res.data.data.createTopic.error);
          reject('Unknown API error');
        },
        () => {
          reject('Response Error');
        }
      );
    });
  },

  edit(data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      const obj = {
        channelId: data.channelId,
        image: data.image,
        topic: {
          title: data.name,
          description: data.description,
          image: null,
          isOfficial: false, //data.official || false,
          isPrivate: data.channel_type == 'private',
          tags: [
            ...data.languages.map((i) => (i && i.code ? i.code : null)),
            ...data.tags.map((i) => (i && i.code ? i.code : null)),
          ],
        },
      };

      const operations = {
        query: `
        mutation ($channelId: UUID!, $topic:TopicInputType!) { 
          editTopic(channelId: $channelId, topic: $topic) 
            ${RESPONSES.CREATE_TOPIC_RESPONSE_TYPE} 
        }`,
        variables: obj,
      };

      if (data.image) {
        const map = {
          image: ['variables.topic.image'],
        };
        formData.append('map', JSON.stringify(map));
        formData.append('image', data.image);
      }
      formData.append('operations', JSON.stringify(operations));

      ApiService.post(GQL_URL, formData).then(
        (res) => {
          if (!res || !res.data || !res.data.data || !res.data.data.editTopic)
            return reject('API Error');
          if (res.data.data.editTopic && res.data.data.editTopic.success)
            return resolve(res.data.data.editTopic);
          if (res.data.data.editTopic.error)
            return reject(res.data.data.editTopic.error);
          reject('Unknown API error');
        },
        () => {
          reject('Response Error');
        }
      );
    });
  },

  addMembers(options) {
    return ApiService.post(GQL_URL, {
      query: `
        mutation($channelId: UUID!, $invitedUsers: [UUID!]!) { 
          inviteToTopic(channelId:$channelId, invitedUsers:$invitedUsers) 
          ${RESPONSES.SUCCESS_ERROR_TYPE}
        }`,
      variables: { ...options },
    });
  },

  kickMember(options) {
    return ApiService.post(GQL_URL, {
      query: `
        mutation($channelId: UUID!, $userId: UUID!) { 
          kickOutTopic(channelId:$channelId, userId:$userId) 
          ${RESPONSES.SUCCESS_ERROR_TYPE}
        }`,
      variables: { ...options },
    });
  },
};

export const Posts = {
  listForWorkspace(options) {
    let params = {};
    if (options.offset) params.offset = options.offset;
    if (options.limit) params.limit = options.limit + 1;
    if (options.channelId) params.channelId = options.channelId;

    return new Promise((resolve, reject) => {
      ApiService.post(GQL_URL, {
        query: `
          query($channelId: UUID!, $limit: Int, $offset: Int) { 
            getMessages(channelId:$channelId, limit:$limit, offset:$offset) 
              ${RESPONSES.LIST_POSTS_TYPE.replace(
                '$imageWidth',
                options.imageWidth ? options.imageWidth : 400
              ).replace(
                '$imageHeight',
                options.imageHeight ? options.imageHeight : 300
              )}
          }
        `,
        variables: {
          ...params,
        },
      }).then(
        (res) => {
          if (res.data && res.data.data && res.data.data.getMessages) {
            const messages = res.data.data.getMessages;
            resolve({
              hasNextPage: messages.length > options.limit,
              hasPrevPage: params.offset > 0,
              data: {
                data: {
                  getMessages: messages.slice(0, options.limit),
                },
              },
            });
          } else {
            reject(res);
          }
        },
        (err) => reject(err)
      );
    });
  },
  deleteMessage(postId) {
    return ApiService.post(GQL_URL, {
      query: `
        mutation($messageId:UUID!) { 
          deleteMessage(messageId:$messageId) 
            ${RESPONSES.SUCCESS_ERRORS_TYPE}
        }
      `,
      variables: {
        messageId: postId,
      },
    });
  },
  read(options) {
    return ApiService.post(GQL_URL, {
      query: `
        mutation($messageId:UUID,$channelId:UUID,$timestamp:DateTime) { 
          readMessage(messageId:$messageId,channelId:$channelId,timestamp:$timestamp) 
            ${RESPONSES.SUCCESS_ERRORS_TYPE}
        }
      `,
      variables: {
        messageId: options.messageId,
        channelId: options.channelId,
        timestamp: new Date().toISOString(),
      },
    });
  },

  listForUser(options) {
    let params = {};
    if (options.offset) params.offset = options.offset;
    if (options.limit) params.limit = options.limit + 1;
    if (options.channelId) params.channelId = options.channelId;

    return new Promise((resolve, reject) => {
      ApiService.post(GQL_URL, {
        query: `
          query($channelId: UUID!) { 
            getChat(channelId:$channelId) 
              ${RESPONSES.FULL_CHAT_TYPE.replace(
                '$imageWidth',
                options.imageWidth ? options.imageWidth : 400
              ).replace(
                '$imageHeight',
                options.imageHeight ? options.imageHeight : 300
              )}
          }
        `,
        variables: {
          ...params,
        },
      }).then(
        (res) => {
          if (res.data && res.data.data && res.data.data.getChat) {
            const messages = res.data.data.getChat.messages;
            resolve({
              hasNextPage: messages.length > options.limit,
              hasPrevPage: params.offset > 0,
              data: {
                data: {
                  getChat: messages.slice(0, options.limit),
                },
              },
            });
          } else {
            reject(res);
          }
        },
        (err) => reject(err)
      );
    });
  },

  react(options) {
    return ApiService.post(GQL_URL, {
      query: `
        mutation ($messageId: UUID!, $reaction: POST_REACTION_ENUM!) { 
          reactToChatMessage(messageId:$messageId, reaction:$reaction) 
            { success, errors } 
        }`,
      variables: {
        messageId: options.messageId,
        reaction: options.reaction,
      },
    });
  },

  listReactions({ postId, limit, offset }) {
    return new Promise((resolve, reject) => {
      ApiService.post(GQL_URL, {
        query: `
          query ($messageId: UUID!, $limit:Int, $offset:Int) { 
            getMessage(messageId:$messageId) 
              { reactions { reaction, count, users (limit:$limit, offset:$offset) { id, fullName, avatar(imageWidth:80,imageHeight:80) } } } 
          }`,
        variables: {
          messageId: postId,
          limit: limit + 1,
          offset,
        },
      }).then(
        (res) => {
          if (res.data && res.data.data && res.data.data.getMessage) {
            const reactions = res.data.data.getMessage.reactions;
            reactions.forEach((r) => {
              r.hasNextPage = r.users.length > limit;
              r.hasPrevPage = offset > 0;
            });
            resolve({
              reactions: reactions.slice(0, limit),
            });
          } else {
            reject(res);
          }
        },
        (err) => reject(err)
      );
    });
  },
};

export const Replies = {
  add(options) {
    return ApiService.post(GQL_URL, {
      query: `
        mutation ($newMessage:ChatMessageInputType!) { 
          sendChatMessage(newMessage:$newMessage) 
            { success, errors } 
        }`,
      variables: {
        newMessage: options,
      },
    });
  },
  edit(options) {
    return ApiService.post(GQL_URL, {
      query: `
        mutation ($blocks:[MessageBlockInputType]!, $postId:UUID!) { 
          editChatMessage(blocks:$blocks, postId:$postId) 
            { success, errors } 
        }`,
      variables: {
        blocks: options.blocks,
        postId: options.postId,
      },
    });
  },
};

export const Users = {
  explore(options) {
    const originalLimit = options.limit;
    const newLimit = options.limit + 1;

    return new Promise((resolve, reject) => {
      ApiService.post(GQL_URL, {
        query: `
          query ($searchString:String, $sortBy:FILTER_SORT_USERS_ENUM, $filterConnections: FILTER_CONNECTIONS_ENUM, $languages:[String], $teachings:[String], $limit:Int, $offset:Int, $excludeChannelId: UUID) { 
            usersSearch(searchString: $searchString, sortBy: $sortBy, filterConnections: $filterConnections, languages: $languages, teachings: $teachings, limit: $limit, offset: $offset, excludeChannelId: $excludeChannelId) 
            ${RESPONSES.LIST_USERS_TYPE}
          }`,
        variables: { ...options, limit: newLimit },
      }).then(
        (res) => {
          if (res.data && res.data.data && res.data.data.usersSearch) {
            const users = res.data.data.usersSearch;
            resolve({
              hasNextPage: users.length > originalLimit,
              hasPrevPage: options.offset > 0,
              data: {
                data: {
                  usersSearch: users.slice(0, originalLimit),
                },
              },
            });
          } else {
            reject(res);
          }
        },
        (err) => reject(err)
      );
    });
  },
};
