// API_URL defined by .env file like this:
// API_URL="'https://api.path.com/'"

/* eslint-disable */ // (doesnt know ENV)
const APP_NAME = ENV.APP_NAME || 'Web';
const APP_FULL_NAME = APP_NAME + (ENV.APP_SUBNAME || ' admin');
/* eslint-enable */

const NUMBER_OF_RESULTS_PER_PAGE = 5;
const NUMBER_OF_MESSAGES_PER_PAGE = 20;
const NUMBER_OF_TOPICS_TO_SUBSCRIBE = 20;
const NUMBER_OF_TOPICS_PER_PAGE = 10;

export {
  APP_NAME,
  APP_FULL_NAME,
  NUMBER_OF_RESULTS_PER_PAGE,
  NUMBER_OF_MESSAGES_PER_PAGE,
  NUMBER_OF_TOPICS_TO_SUBSCRIBE,
  NUMBER_OF_TOPICS_PER_PAGE,
};
