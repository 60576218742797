import { Auth, ApiService } from '@/services/api.service';
import TokenService from '@/services/token.service';

const DEBUG = false;

export default {
  state: {
    loggedIn: false,
    emailSent: false,
    authorizedUser: {
      token: null,
      email: null,
      jwt: null,
      refresh: null,
    },
    renewing: false,
  },
  mutations: {
    setApiToken(state, { email, token }) {
      state.emailSent = true;
      state.authorizedUser = {
        email: email,
        token: token,
        jwt: null,
        refresh: null,
      };
      TokenService.saveToken(state.authorizedUser);
      setTimeout(ApiService.setHeader);
    },
    removeApiToken(state) {
      state.emailSent = false;
      state.authorizedUser = {
        token: null,
        email: null,
        jwt: null,
        refresh: null,
      };
      TokenService.destroyToken();
      setTimeout(ApiService.clearHeader);
    },
    setJWTToken(state, { token, refresh }) {
      state.authorizedUser.jwt = token;
      state.authorizedUser.refresh = refresh;
      TokenService.saveJWT({
        token: token,
        refresh: refresh,
      });
      ApiService.setJWTHeader();
    },
    login(state) {
      state.loggedIn = true;
    },
    logout(state) {
      state.loggedIn = false;
      this.commit('removeApiToken');
    },
  },
  actions: {
    // getApiToken(context) {
    //   return context.
    // },
    setApiToken(context, user) {
      context.commit('setApiToken', user);
    },
    removeApiToken(context) {
      context.commit('removeApiToken');
    },
    checkAuth(context) {
      return new Promise((resolve, reject) => {
        if (TokenService.getJWT()) {
          DEBUG && console.log('AUTH: have stored token');
          ApiService.setJWTHeader();
          resolve(true);
        } else {
          reject('AUTH: No local token');
          context.dispatch('removeApiToken');
        }
      });
    },
    renewAuth(context) {
      return new Promise((resolve, reject) => {
        context.state.renewing = true;
        DEBUG && console.log('AUTH: started renew');
        if (!TokenService.getJWTRefresh()) reject('AUTH: No refresh token');
        Auth.renew({
          refresh: TokenService.getJWTRefresh(),
          token: TokenService.getJWT(),
        }).then(
          (response) => {
            if (
              !response ||
              !response.data ||
              !response.data.data ||
              !response.data.data.refreshToken
            )
              return reject();
            let respData = response.data.data.refreshToken;

            if (
              !respData ||
              !respData.success ||
              !respData.jwtSangha ||
              !respData.jwtSangha.mantra
            ) {
              return reject('AUTH: No valid response');
            }

            if (
              respData.jwtSangha.mantra !=
              'OM A HUM VAJRA GURU PADMA SIDDHI HUM'
            ) {
              context.commit('removeApiToken');
              return reject('AUTH: No mantra');
            }

            DEBUG && console.log('AUTH: renew success');

            let jwt = {
              token: respData.jwtSangha.token,
              refresh: respData.jwtSangha.refreshToken,
            };
            context.commit('setJWTToken', jwt);
            context.state.renewing = false;
            resolve(jwt);
          },
          (err) => {
            reject(err);
          }
        );
      });
    },
  },
};
