import { Profile } from '@/services/api.service';

export default {
  state: {
    id: null,
    fullName: '',
    avatar: null,
  },
  mutations: {
    setProfile(state, { id, full_name, avatar }) {

      state.id = id;
      state.fullName = full_name;
      state.avatar = avatar;
    },
  },
  actions: {
    fetchProfile(context) {
      return new Promise((resolve, reject) => {
        if (context.state.id !== null) {
          return resolve(true);
        }

        Profile.my()
          .then(
            (profileRes) => {
              if (!profileRes || !profileRes.data || !profileRes.data.data)
                reject();
              let respData = profileRes.data.data.getSelf;
              if (!respData) reject();
              context.commit('setProfile', respData);
              resolve(true);
            },
            (err) => reject(err)
          )
          .catch((err) => reject(err));
      });
    },
  },
};
