import { APP_FULL_NAME } from '@/services/config';

export function lazyLoad(view, folder = 'pages') {
  return () => {
    // eslint-disable-next-line
    return import(`@/${folder}/${view}.vue`);
  };
}

function getTitle(title) {
  return `${title} - ${APP_FULL_NAME}`;
}

export const routes = [
  {
    path: '/login',
    name: 'login',
    component: lazyLoad('Login'),
    meta: {
      title: getTitle('Login'),
      needsAuth: false,
    },
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: lazyLoad('RecoveryLinkLogin'),
    meta: {
      title: getTitle('Verify access'),
      needsAuth: false,
    },
  },
  {
    path: '/events/',
    name: 'events',
    component: lazyLoad('EventList'),
    meta: {
      title: getTitle('Events list'),
      needsAuth: true,
    },
  },
  {
    path: '/event/create/:id?',
    name: 'event-create',
    component: lazyLoad('EventCreate'),
    meta: {
      title: getTitle('Create event'),
      needsAuth: true,
    },
  },
  {
    path: '/event/edit/:id',
    name: 'event-edit',
    component: lazyLoad('EventCreate'),
    meta: {
      title: getTitle('Edit event'),
      needsAuth: true,
    },
  },
  {
    path: '/event/:id',
    name: 'public-event',
    component: lazyLoad('EventDetail'),
    meta: {
      title: getTitle('Event detail'),
      needsAuth: false,
      showHeader: false,
    },
  },
  {
    path: '/venue/create',
    name: 'venue-create',
    component: lazyLoad('VenueCreateModal', 'components'),
    meta: {
      title: getTitle('Create venue'),
      needsAuth: true,
    },
  },
  {
    path: '/',
    redirect: '/events/',
    name: 'index',
  },
  {
    name: 'going',
    path: '/events/going',
    redirect: '/events/?listingType=going',
  },
  {
    path: '/events/created',
    redirect: '/events/?listingType=created',
  },
  {
    path: '/events/suggested',
    redirect: '/events/?listingType=suggested',
  },
  {
    path: '/together/channels/:id?',
    name: 'channel',
    component: lazyLoad('Channel', 'pages/Together'),
    meta: {
      title: getTitle('Channel'),
      needsAuth: true,
    },
  },
  {
    path: '/together/messages/:id?',
    name: 'messages',
    component: lazyLoad('DirectMessage', 'pages/Together'),
    meta: {
      title: getTitle('Direct Messages'),
      needsAuth: true,
    },
  },
  {
    path: '/together/:type/:id/post/:postId',
    name: 'thread',
    component: lazyLoad('Thread', 'pages/Together'),
    meta: {
      title: getTitle('Thread - Together'),
      needsAuth: true,
    },
  },
  {
    path: '/alendar/:page?',
    name: 'alendar',
    component: lazyLoad('Alendar'),
    meta: {
      title: getTitle('Alendar'),
      needsAuth: true,
    },
  },
  {
    path: '/help/:page?',
    name: 'help',
    component: lazyLoad('Help'),
    meta: {
      title: getTitle('Help'),
      needsAuth: false,
    },
  },
  { path: '*', component: lazyLoad('404', 'pages') },
];
