//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SanghaAvatar from '@/components/SanghaAvatar.vue';
import { Profile } from '@/services/api.service';
import { apolloClient } from '@/services/apollo.service';
import { EventBus } from '@/services/event-bus';
import gql from 'graphql-tag';

export default {
  name: 'LayoutDefault',
  components: {
    SanghaAvatar,
  },

  data() {
    return {
      lastListingUrl: null,
      leftDrawerOpen: false,
      showNotifications: false,
      notificationsCount: 0,
      interval: null,
      profileLoaded: false,
      subscription: null,
      tabCounts: {
        unreadChannelsCount: 0,
        unreadDmCount: 0,
        unreadNotificationCount: 0,
      },
    };
  },
  methods: {
    gotoEvents() {
      if (this.$route.name !== 'events') {
        this.$router.replace({ name: 'events' }).catch(() => {
          return 0;
        });
      } else {
        EventBus.$emit('loadEvents');
      }
    },
    gotoLogin() {
      if (this.$route.name !== 'login')
        this.$router.push({ name: 'login' }).catch(() => {
          return 0;
        });
    },
    gotoHelp() {
      if (this.$route.name !== 'help')
        this.$router.push({ name: 'help' }).catch(() => {
          return 0;
        });
    },
    gotoAlendar() {
      if (this.$route.name !== 'alendar')
        this.$router.push({ name: 'alendar' }).catch(() => {
          return 0;
        });
    },
    logout() {
      this.$store.commit('logout');
      if (this.$route.name != 'login')
        this.$router.push({ name: 'login' }).catch(() => {
          return 0;
        });
    },
    goBack() {
      if (this.$route.name == 'channel') {
        return this.$router.push({ name: 'channels-together' });
      }
      this.$router.go(-1);
    },
    setLanguage(language) {
      if (language) {
        localStorage.sanghaLanguage = language;
        setTimeout(() => {
          this.$router.go(0);
        }, 100);
      }
    },
    loadProfile() {
      this.$store.dispatch('fetchProfile').then(
        (res) => {
          this.profileLoaded = res;
          if (res) {
            this.loadCounts();
            this.startSubscription();
          }
        },
        () => {
          this.profileLoaded = false;
        }
      );
    },
    loadCounts() {
      Profile.getUnreadTopics().then(
        (res) => {
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.getUnreadChannelsCount
          ) {
            this.tabCounts = {
              unreadChannelsCount:
                res.data.data.getUnreadChannelsCount.unreadChannelsCount,
              unreadDmCount:
                res.data.data.getUnreadChannelsCount.unreadDirectMessagesCount,
              unreadNotificationCount:
                res.data.data.getUnreadChannelsCount.unreadNotificationCount,
            };
          }
        },
        () => {}
      );
    },
    startSubscription() {
      if (this.subscription) return;
      const COUNTS_SUBSCRIPTION = gql`
        subscription onUpdateCounts {
          onUpdateCounts {
            unreadChannelsCount
            unreadDmCount
            unreadNotificationCount
          }
        }
      `;
      let sub = apolloClient.subscribe({
        query: COUNTS_SUBSCRIPTION,
        errorPolicy: 'all',
      });

      this.subscription = sub.subscribe({
        errorPolicy: 'all',
        next(data) {
          if (data && data.data && data.data.onUpdateCounts) {
            const newState = data.data.onUpdateCounts;
            EventBus.$emit('onCountUpdated', newState);
          }
        },
        error(err) {
          console.error('Subscription error', err);
        },
      });
    },
  },
  computed: {
    headerColor() {
      return null;
    },
  },
  mounted() {
    EventBus.$on('onListingUrlChange', (url) => {
      if (this.lastListingUrl != url) this.lastListingUrl = url;
    });

    EventBus.$on('onCountUpdated', (countObj) => {
      this.tabCounts = { ...countObj };
    });

    EventBus.$on('onListingRequested', (additionalParams) => {
      let url = this.lastListingUrl ? this.lastListingUrl : '?108';
      if (additionalParams) {
        Object.keys(additionalParams).forEach((key) => {
          if (parseInt(key) == 108) return;
          url += '&' + key + '=' + additionalParams[key];
        });
      }
      // console.log("onListingRequested", additionalParams, url)
      let params = new URLSearchParams(url.replace('?', ''));
      let paramsObj = Object.fromEntries(params.entries());
      this.$router
        .push({
          name: 'events',
          params: { listingType: 'created' },
          query: paramsObj,
        })
        .catch(() => {});
    });

    // this.loadProfile();
  },
  beforeDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  },
  destroyed() {
    EventBus.$off('onListingUrlChange');
    EventBus.$off('onListingRequested');
    EventBus.$off('onCountUpdated');
    clearInterval(this.interval);
  },
  watch: {
    $route(to) {
      if (to.name == 'login' || to.name == 'recovery') return;
      if (!this.profileLoaded) this.loadProfile();
    },
  },
};
