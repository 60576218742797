//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SanghaAvatar',
  props: ['src', 'larger', 'medium'], // i know, its trash
  data() {
    return {
      lazyImage: null,
    };
  },
  methods: {
    onIntersection(o) {
      if (o.isIntersecting) this.lazyImage = this.src;
    },
  },
  mounted() {},
  watch: {
    src(newVal) {
      this.lazyImage = newVal;
    },
  },
  computed: {},
};
