import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth.store';
import profile from './profile.store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    profile,
  },
});
