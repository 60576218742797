import { ApiService } from '@/services/api.service';
import { TimeHelper } from '@/services/helpers';
import { i18n, locale } from '@/services/i18n.init';
import { routes } from '@/services/routes';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import moment from 'moment-timezone';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import App from './App.vue';
import './quasar';
// CSS and SCSS imports
import './scss/custom.scss';
import store from './stores/store';

import Plugin from '@quasar/quasar-ui-qcalendar' // quasar ui calendar
import '@quasar/quasar-ui-qcalendar/dist/index.css'


moment.locale(locale);

/*eslint-disable */
if (ENV.ENVIRONMENT != 'develop') {
  /*eslint-enable */
  Sentry.init({
    dsn: 'https://6fc45af040eb444e9914da7155c68694@o171131.ingest.sentry.io/5287707',
    integrations: [new VueIntegration({ Vue, attachProps: true })],
    /*eslint-disable */
    environment: ENV.ENVIRONMENT,
    /*eslint-enable */
  });
} else {
  console.log('Develop mode, Sentry disabled');
}

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(Plugin);

ApiService.init();
// PublicApiService.init();

// FILTERS
Vue.filter('formatDate', function (value, format) {
  if (!format) format = 'dddd, MMMM Do YYYY, HH:mm z';
  if (!value) return;
  return moment(String(value))
    .tz(TimeHelper.getDefaultTimezone())
    .format(format);
});
Vue.filter('formatDateUtc', function (value) {
  if (!value) return;
  return moment(String(value)).tz('UTC').format('dddd, MMMM Do YYYY, HH:mm z');
});
Vue.filter('monthName', function (value) {
  if (!value) return;
  return moment(String(value))
    .tz(TimeHelper.getDefaultTimezone())
    .format('MMM');
});
Vue.filter('day', function (value) {
  if (!value) return;
  return moment(String(value)).tz(TimeHelper.getDefaultTimezone()).format('D');
});

Vue.filter('ago', function (value) {
  if (!value) return;
  return moment(String(value)).tz(TimeHelper.getDefaultTimezone()).fromNow();
});

Vue.filter('dayOfWeek', function (value) {
  if (!value) return;
  return moment(String(value))
    .tz(TimeHelper.getDefaultTimezone())
    .format('ddd');
});

// eslint-disable-next-line
const basePath = ENV.PUBLIC_PATH || '/web_admin/';

export const router = new VueRouter({
  mode: 'history',
  base: basePath,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
  next();
});

router.beforeEach((to, from, next) => {
  if (to.meta.needsAuth) {
    store
      .dispatch('checkAuth')
      .then(next)
      .catch(() => {
        next({ name: 'login', query: { returnTo: to.fullPath } });
      });
  } else {
    next();
  }
});

setTimeout(ApiService.enableInterceptor);

new Vue({
  i18n,
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
